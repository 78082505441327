export function liveAthleteScore(leaderboard, overall_results, athlete_id, athlete_name, gender, current_obstacle, fails, time, courseDesc, current_place){
    const p = new Promise((res,reject)=>{
        let ind = leaderboard.findIndex((obj => obj.athlete_id == athlete_id));
        let new_place = null;
        let ovr_place = null;
        leaderboard[ind].total_fails = fails;
        leaderboard[ind].tiebreak_time = '00:' + time;
        leaderboard[ind].points = current_obstacle - 1;
        if(courseDesc == 'Speed Course'){
            leaderboard.filter(gen => gen.gender == gender).sort((a, b) => b.points - a.points || a.tiebreak_time.localeCompare(b.tiebreak_time)).map((item, index) => {
                if(item.athlete_id == athlete_id){
                    new_place = index + 1;
                }
                item.athlete_rank = index + 1;
            })
        }else{
            leaderboard.filter(gen => gen.gender == gender).sort((a, b) => b.points - a.points || a.total_fails - b.total_fails || a.tiebreak_time.localeCompare(b.tiebreak_time)).map((item, index) => {
                if(item.athlete_id == athlete_id){
                    new_place = index + 1;
                }
                item.athlete_rank = index + 1;
            })
        }

        if(courseDesc != 'Open Round'){
            let indx = overall_results.findIndex((obj => obj.athlete_id == athlete_id));
            if(indx == -1){
                overall_results.push({
                  athlete_id: athlete_id,
                  athlete_rank: overall_results.length +1,
                  burnout_rank: null,
                  hybrid_rank: null,
                  speed_rank: null,
                  gender: gender,
                  first_name: athlete_name.slice(0,1),
                  last_name: athlete_name.slice(athlete_name.indexOf(' '),athlete_name.length),
                  points: null,
                  top_score: null,
                  top_two_score: null
                });
                indx = overall_results.findIndex((obj => obj.athlete_id == athlete_id));
            }
            console.log('DEBUG IF');
            console.log(new_place);
            console.log(current_place);
            console.log(leaderboard.length);
            // Change This number to adjust bottom live leaderbaord xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            var maxPoints = 10 + 1; // First number is the max points you want to award (10 for 10 points)
            // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

            if(new_place != current_place || new_place == leaderboard.length){
            switch(courseDesc){
                case 'Hybrid Course':
                    overall_results[indx].hybrid_rank = new_place;
                    overall_results[indx].points = calculatePoints(maxPoints, new_place, overall_results[indx].speed_rank, overall_results[indx].burnout_rank);
                    overall_results[indx].top_score = calculateTopScore(maxPoints, [overall_results[indx].hybrid_rank, overall_results[indx].speed_rank,overall_results[indx].burnout_rank]);
                    overall_results[indx].top_two_score = calculateTopTwoScore(maxPoints, [overall_results[indx].hybrid_rank, overall_results[indx].speed_rank,overall_results[indx].burnout_rank]);
                    overall_results.filter(gen => gen.gender == gender).map((item, index) => {
                        if(item.athlete_id !== athlete_id){
                            if(item.hybrid_rank >= new_place && item.hybrid_rank < current_place){
                                item.hybrid_rank += 1;
                                item.points = calculatePoints(maxPoints, item.hybrid_rank, item.speed_rank, item.burnout_rank);
                                item.top_score = calculateTopScore(maxPoints, [item.hybrid_rank, item.speed_rank,item.burnout_rank]);
                                item.top_two_score = calculateTopTwoScore(maxPoints, [item.hybrid_rank, item.speed_rank,item.burnout_rank]);
                            }
                        }
                    });
                    break;
                case 'Speed Course':
                    overall_results[indx].speed_rank = new_place;
                    overall_results[indx].points = calculatePoints(maxPoints, new_place, overall_results[indx].hybrid_rank, overall_results[indx].burnout_rank);
                    overall_results[indx].top_score = calculateTopScore(maxPoints, [overall_results[indx].hybrid_rank, overall_results[indx].speed_rank,overall_results[indx].burnout_rank]);
                    overall_results[indx].top_two_score = calculateTopTwoScore(maxPoints, [overall_results[indx].hybrid_rank, overall_results[indx].speed_rank,overall_results[indx].burnout_rank]);
                    overall_results.filter(gen => gen.gender == gender).map((item, index) => {
                        if(item.athlete_id !== athlete_id){
                            if(item.speed_rank >= new_place && item.speed_rank < current_place){
                                item.speed_rank += 1;
                                item.points = calculatePoints(maxPoints, item.hybrid_rank, item.speed_rank, item.burnout_rank);
                                item.top_score = calculateTopScore(maxPoints, [item.hybrid_rank, item.speed_rank,item.burnout_rank]);
                                item.top_two_score = calculateTopTwoScore(maxPoints, [item.hybrid_rank, item.speed_rank,item.burnout_rank]);
                            }
                        }
                    })
                    break;
                case 'Burnout Course':
                    overall_results[indx].burnout_rank = new_place;
                    overall_results[indx].points = calculatePoints(maxPoints, new_place, overall_results[indx].hybrid_rank, overall_results[indx].speed_rank);
                    overall_results[indx].top_score = calculateTopScore(maxPoints, [overall_results[indx].hybrid_rank, overall_results[indx].speed_rank,overall_results[indx].burnout_rank]);
                    overall_results[indx].top_two_score = calculateTopTwoScore(maxPoints, [overall_results[indx].hybrid_rank, overall_results[indx].speed_rank,overall_results[indx].burnout_rank]);
                    overall_results.filter(gen => gen.gender == gender).map((item, index) => {
                        if(item.athlete_id !== athlete_id){
                            if(item.burnout_rank >= new_place && item.burnout_rank < current_place){
                                item.burnout_rank += 1;
                                item.points = calculatePoints(maxPoints, item.hybrid_rank, item.speed_rank, item.burnout_rank);
                                item.top_score = calculateTopScore(maxPoints, [item.hybrid_rank, item.speed_rank,item.burnout_rank]);
                                item.top_two_score = calculateTopTwoScore(maxPoints, [item.hybrid_rank, item.speed_rank,item.burnout_rank]);
                            }
                        }
                    })
                    break;
            }
        }
            overall_results.filter(gen => gen.gender == gender).sort((a, b) => b.points - a.points || b.top_score - a.top_score || b.top_two_score - a.top_two_score).map((item, index) => {
                if(item.athlete_id == athlete_id){
                    ovr_place = index + 1;
                }
                item.athlete_rank = index + 1;
            })
        }

        let new_arr = leaderboard.sort((a,b)=> a.athlete_rank - b.athlete_rank);
        let ovr_arr = overall_results.sort((a,b)=> a.athlete_rank - b.athlete_rank);
        res({result_arr: new_arr, new_place: new_place, ovr_result: ovr_arr, ovr_place: ovr_place});
    })
    return p;
}

function calculatePoints(outOf, place, course2, course3){
    let val = (outOf - place) + (course2 > 0 ? (outOf - course2) : 0) + (course3 > 0 ? (outOf - course3) : 0);
    return val;
}

function calculateTopScore(outOf, arr){
    let val = outOf - arr.filter(obj=> obj > 0).sort((a,b)=>a-b)[0];
    return val;
}

function calculateTopTwoScore(outOf, arr){
    let val = (outOf - arr.filter(obj=> obj > 0).sort((a,b)=>a-b)[0]) + (arr.filter(obj=> obj > 0).sort((a,b)=>a-b)[1] > 0 ? outOf - arr.filter(obj=> obj > 0).sort((a,b)=>a-b)[1]: 0);
    return val;
}


export function addCurrentAthlete(leaderboard, athlete_id, athlete_name, gender){
    const p = new Promise((res,reject)=>{
        let new_arr = leaderboard.filter(gen => gen.gender == gender);
        let new_place = new_arr.length + 1;
        let athlete_object ={
            first_name: athlete_name.slice(0,1),
            last_name: athlete_name.slice(athlete_name.indexOf(' '),athlete_name.length),
            athlete_id: athlete_id,
            athlete_rank: new_arr.length + 1,
            gender: gender,
            loc: 0,
            points: 0,
            tiebreak_time: "00:00:00.00",
            total_fails: 0        
        };
    new_arr.push(athlete_object);
            res({result_arr: new_arr, new_place: new_place});
    })
    return p;
}