import React, { Component } from 'react';
import { connect } from 'react-redux';
import NSNHeader from "../components/header";
import '../style/results_entry.css';
import '../style/graphic_leaderboard.css';
import { getRunOrder_LeaderBoard, getComps } from '../api/api';
import { Column, Row } from 'simple-flexbox';
import Stopwatch from '../components/StopWatch';
import timeFormat, { zeroPad } from '../functions/timeFormat';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faCircle } from '@fortawesome/free-solid-svg-icons';
import queryString from 'query-string';
import SponsorLogo from '../static/BOC_Logo_White.png';
import MainWebSocket from "../components/WebSocket";


class PublicLeaderboard extends Component {

    state = {
        results: [],
        comp_id: '',
        scoreboard_gender: '',
        event: {},
        result_type: '',
        course_type: '',
        advanced_details: false,
        compsArr: [],
        show_background: false,
        limit_finalists: 0,
        season: '4'
    }

    handleChange = name => event => {
        this.props.history.push({
            pathname: 'public_leaderboard',
            search: '?' + new URLSearchParams({comp_id: this.state.comp_id,
                gender: event.target.value,
                table: this.state.course_type,
                type: this.state.result_type}).toString() 
        });
        this.setState({
            [name]: event.target.value,
        });
    };

    setfilter (filt, val) {
        
        this.setState({
            [filt]: val,
        });
        this.props.history.push({
            pathname: 'public_leaderboard',
            search: '?' + new URLSearchParams({comp_id: this.state.comp_id,
                gender: filt == 'scoreboard_gender' ? val : this.state.scoreboard_gender,
                table: filt == 'course_type' ? val : this.state.course_type,
                type: filt == 'result_type' ? val : this.state.result_type}).toString() 
        });
        this.updateFiltData(filt, val);
    };

    goTo(id){
        //   this.props.history.push({
        //       pathname: '/athlete_bio',
        //       search: '?athlete_id=' + id
        //   })

          window.open('/athlete_bio?athlete_id=' + id, "_blank");
    }

    updateFiltData (filt, val) {
        var comp;
        var course;
        var data_type;
        var gender;
        switch (filt) {
            case 'comp_id':
                comp = val;
                course = this.state.course_type;
                data_type = this.state.result_type;
                gender = this.state.scoreboard_gender;
                break;
            case 'course_type':
                comp = this.state.comp_id;
                course = val;
                data_type = this.state.result_type;
                gender = this.state.scoreboard_gender;
                break;
                case 'scoreboard_gender':
                comp = this.state.comp_id;
                course = this.state.course_type;
                data_type = this.state.result_type;
                gender = val;
                break;
            case 'result_type':
                comp = this.state.comp_id;
                course = this.state.course_type;
                gender = this.state.scoreboard_gender;
                data_type = val;
                if(val == 'leaderboard' && this.state.scoreboard_gender == 'Both'){
                    gender = 'Male';
                }
                break;
            default:
                comp = this.state.comp_id;
                course = this.state.course_type;
                data_type = this.state.result_type;
                gender = this.state.scoreboard_gender;
                break;
        }

        this.props.history.push({
            pathname: 'public_leaderboard',
            search: '?' + new URLSearchParams({comp_id: comp,
                gender: gender,
                table: course,
                type: data_type}).toString() 
        });

        getRunOrder_LeaderBoard(comp, course, data_type).then(resp => {
            console.log(resp);
            this.setState({ comp_id: comp, results: resp.results, course_type: course, result_type: data_type, limit_finalists: resp.results.length, scoreboard_gender: gender });
        });
    }

    updateData = name => event => {
        var comp;
        var course;
        var data_type;
        var gender = this.state.scoreboard_gender;
        switch (name) {
            case 'comp_id':
                comp = event.target.value;
                course = this.state.course_type;
                data_type = this.state.result_type;
                break;
            case 'course_type':
                comp = this.state.comp_id;
                course = event.target.value;
                data_type = this.state.result_type;
                break;
            case 'result_type':
                comp = this.state.comp_id;
                course = this.state.course_type;
                data_type = event.target.value;
                if(event.target.value == 'leaderboard'){
                    gender = 'Male';
                }
                break;
            default:
                comp = this.state.comp_id;
                course = this.state.course_type;
                data_type = this.state.result_type;
                break;
        }

        this.props.history.push({
            pathname: 'public_leaderboard',
            search: '?' + new URLSearchParams({comp_id: comp,
                gender: gender,
                table: course,
                type: data_type}).toString() 
        });

        getRunOrder_LeaderBoard(comp, course, data_type).then(resp => {
            console.log(resp);
            this.setState({ comp_id: comp, results: resp.results, course_type: course, result_type: data_type, limit_finalists: resp.results.length, scoreboard_gender: gender });
        });
    }

    updateGraphics = (message) =>{
        var parseData = JSON.parse(message.slice(0, -1));
            console.log(parseData);
            console.log(this);
            if(parseData.clock_status == 'athlete_submitted') {
                var courseDesc;
                switch (parseData.result_table) {
                  case 'open_results':
                    courseDesc = 'Open Round';
                    break;
                  case 'speed_results':
                    courseDesc = 'Speed Course';
                    break;
                  case 'hybrid_results':
                    courseDesc = 'Hybrid Course';
                    break;
                  case 'burnout_results':
                    courseDesc = 'Burnout Course';
                    break;
    
                }
                getRunOrder_LeaderBoard(parseData.event_id, this.state.course_type, 'leaderboard').then(resp => {
                    this.setState({ results: resp.results});
                });
            }
    }

    handleCheck = name => event => {
        var result = this.state[name] == true ? false : true;
        this.setState({
            [name]: result,
        });
    };

    componentDidMount() {
        console.log(this);
        var queryObj = queryString.parse(this.props.location.search);
        var query_comp = queryObj.comp_id || '';
        var query_gender = queryObj.gender || 'Both';
        var query_table = queryObj.table || 'open_results';
        var query_type = queryObj.type || 'run_order';
        getComps().then(resp_comps => {
            getRunOrder_LeaderBoard(query_comp, query_table, query_type).then(resp => {
                console.log(resp);
                this.setState({ compsArr: resp_comps.comps, results: resp.results, comp_id: query_comp, scoreboard_gender: query_gender, result_type: query_type, course_type: query_table , limit_finalists: resp.results.length});
            });
        }).catch(err => {
            console.log(err);
        });

    }

    render() {
        return (
            <div className='showBackground'>
                <NSNHeader title='Ninja Sport Network' link='' linkTitle=''></NSNHeader>

                {/* <div className="darkCard">
                    <div className="darkCardTitle">Filter Leaderboard</div>
                    <div className="flex-row" style={{ alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <div className="flex-row" style={{ alignItems: "center" }}>
                            <div className="fieldLabel">
                                Select a competition
                            </div>
                            <div className="field">
                                <select value={this.state.comp_id} onChange={this.updateData('comp_id')}>
                                    <option value=""></option>
                                    {this.state.compsArr.map((item, index) => {
                                        return <option value={item.comp_id}>{item.gym_name} - {item.comp_date.split('T')[0]}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="flex-row" style={{ alignItems: "center" }}>
                            <div className="fieldLabel">
                                Select course type
                            </div>
                            <div className="field">
                                <select value={this.state.course_type} onChange={this.updateData('course_type')}>
                                    <option value="open_results">Open</option>
                                    <option value="speed_results">Speed</option>
                                    <option value="hybrid_results">Hybrid</option>
                                    <option value="burnout_results">Burnout</option>
                                    <option value="overall_results">Final</option>
                                </select>
                            </div>
                        </div>
                        <div className="flex-row" style={{ alignItems: "center" }}>
                            <div className="fieldLabel">
                                Select Athlete Division
                            </div>
                            <div className="field">
                                <select value={this.state.scoreboard_gender} onChange={this.handleChange('scoreboard_gender')}>
                                    {this.state.result_type == 'run_order' ? <option value="Both">Both</option> : null}
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    =
                                </select>
                            </div>
                        </div>
                        <div className="flex-row" style={{ alignItems: "center" }}>
                            <div className="fieldLabel">
                                Data Display
                            </div>
                            <div className="field">
                                <select value={this.state.result_type} onChange={this.updateData('result_type')}>
                                    <option value="leaderboard">Leaderboard</option>
                                    <option value="run_order">Run Order</option>
                                </select>
                            </div>
                        </div>
                        {this.state.course_type == 'overall_results' ?
                            <div className="flex-row" style={{ alignItems: "center" }}>
                                <div className="field">
                                    <input type="checkbox" checked={this.state.advanced_details} onChange={this.handleCheck('advanced_details')} />
                                </div>
                                <div className="fieldLabel">
                                    Show Overall Tiebreakers
                                </div>
                            </div> : null}
                    </div>
                </div> */}

<div className="darkCard">
                    <div className="darkCardTitle">Filter Leaderboard</div>
                    <div className="flex-row" style={{ alignItems: "flex-start", justifyContent: "space-between", flexWrap: "wrap" }}>
                    <div>
                    <div className="flex-row" style={{ alignItems: "center" }}>
                        <div style={{marginRight: "10px"}}>Select Season:</div>
                        <div className={this.state.season == '1' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('season', '1')}>I</div>
                        <div className={this.state.season == '2' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('season', '2')}>II</div>
                        <div className={this.state.season == '3' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('season', '3')}>III</div>
                        <div className={this.state.season == '4' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('season', '4')}>IV</div>
                    </div>
                        <div className="flex-row" style={{ alignItems: "center" }}>
                            <div className="fieldLabel">
                                Select a competition
                            </div>
                            <div className="field">
                                <select value={this.state.comp_id} onChange={this.updateData('comp_id')}>
                                    <option value=""></option>
                                    {this.state.compsArr.filter(seas=>seas.season_id==this.state.season).map((item, index) => {
                                        return <option value={item.comp_id}>{item.gym_name} - {item.comp_date.split('T')[0]}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        </div>
                        {/* <div className="flex-row" style={{ alignItems: "center" }}> */}
                        <div style={{textAlign: "center"}}>
                            <div className="fieldLabel">
                                Select course type
                            </div>
                            <div className="flex-row" style={{justifyContent:"center", alignItems: "center" }}>
                            <div className={this.state.course_type == 'open_results' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('course_type', 'open_results')}>Open</div>
                            <div className={this.state.course_type == 'hybrid_results' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('course_type', 'hybrid_results')}>Hybrid</div>
                            <div className={this.state.course_type == 'speed_results' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('course_type', 'speed_results')}>Speed</div>
                            </div>
                            <div className="flex-row" style={{justifyContent:"center",  alignItems: "center" }}>
                            <div className={this.state.course_type == 'burnout_results' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('course_type', 'burnout_results')}>Burnout</div>
                            <div className={this.state.course_type == 'overall_results' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('course_type', 'overall_results')}>Final</div>
                            </div>
                            </div>
                        {/* </div> */}
                        <div style={{textAlign: "center"}}>
                            <div className="fieldLabel">
                                Select Athlete Division
                            </div>
                            <div className="flex-row" style={{justifyContent:"center", alignItems: "center" }}>
                            <div className={this.state.scoreboard_gender == 'Both' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('scoreboard_gender', 'Both')}>Both</div>       
                            <div className={this.state.scoreboard_gender == 'Male' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('scoreboard_gender', 'Male')}>Male</div>
                            <div className={this.state.scoreboard_gender == 'Female' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('scoreboard_gender', 'Female')}>Female</div>
                            </div>
                            </div>
                            <div style={{textAlign: "center"}}>
                            <div className="fieldLabel">
                                Data Display
                            </div>
                            <div className="flex-row" style={{justifyContent:"center", alignItems: "center" }}>
                            <div className={this.state.result_type == 'run_order' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('result_type', 'run_order')}>Run Order</div>
                            <div className={this.state.result_type == 'leaderboard' ? "filterBx activeFilt" : "filterBx"} onClick={e => this.setfilter('result_type', 'leaderboard')}>Leaderboard</div>
                            </div>
                            </div>
                        {this.state.course_type == 'overall_results' ?
                            <div className="flex-row" style={{ alignItems: "center" }}>
                                <div className="field">
                                    <input type="checkbox" checked={this.state.advanced_details} onChange={this.handleCheck('advanced_details')} />
                                </div>
                                <div className="fieldLabel">
                                    Show Overall Tiebreakers
                                </div>
                            </div> : null}
                    </div>
                </div>

                {this.state.results.length > 0 ?
                    <div>

                        <div className="leaderboardDivision">

                            <div className="athleteDivision">

                                <div className="scoreboardTitleBox">
                                <div>
                                    <img alt='sk_logo' src={SponsorLogo} width="70" height="auto" />
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                        <div className="scoreboardTitle">
                                            {this.state.compsArr.filter(gym=>gym.comp_id == this.state.comp_id).map((item, index)=>{
                                                return <span>{item.gym_name}</span>
                                            })}</div>
                                        <div className="scoreboardSubTitle">
                                        {this.state.course_type == 'open_results' ? <span>Open Course</span> 
                                            :  this.state.course_type == 'speed_results' ? <span>Speed Course</span>
                                        : this.state.course_type == 'hybrid_results' ? <span>Hybrid Course</span>
                                    : this.state.course_type == 'burnout_results' ? <span>Burnout Course</span>
                                : <span>Overall Results</span>}

                                        </div>
                                    </div>
                                    <div>
                                        <img alt='sk_logo' src={SponsorLogo} width="70" height="auto" />
                                        </div>
                                </div>

                                <div className="scoreboardTitleBox" style={{marginTop: "10px"}}>
                                    <div style={{ textAlign: "center" }}>
                                        <div className="scoreboardSubTitle" style={{color: "#94C2E4"}}>
                                        {this.state.scoreboard_gender == 'Male' ? <span>Men's</span> : this.state.scoreboard_gender == 'Female' ? <span>Women's</span> : <span>Overall</span>}
                                            {this.state.result_type == "run_order" ?
                                                <span> Run Order</span>
                                                : <span> Leaderboard</span>
                                            }

                                        </div>
                                    </div>
                                </div>

                                {this.state.result_type == "run_order" ?
                                    this.state.scoreboard_gender == 'Both' ? 
                                        this.state.results.slice(0, this.state.limit_finalists).length > 10 ?
                                        <div style={{ marginTop: "10px", display: "flex" }}>
                                            <div style={{ flex: "1", margin: "0px 10px" }}>
                                                <table className="scoreboardTable" cellspacing="5" style={{ width: "100%" }}>
                                                    <thead className="tableHeader">
                                                        <th colspan="2" style={{ textAlign: "left", paddingLeft: "60px" }}>ATHLETE</th>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.results.slice(0, this.state.limit_finalists).slice(0, Math.round(this.state.results.slice(0, this.state.limit_finalists).length / 2)).sort((a, b) => a.run_order - b.run_order).map((item, index) => {
                                                                return <tr className="athleteBox" onClick={e=>this.goTo(item.athlete_id)}>
                                                                    <td className="rankCell">{item.run_order}</td>
                                                                    <td className="nameCell" style={{ paddingLeft: "20px" }}>{item.first_name} {item.last_name} {item.qualified == 1 ? <sup className="qualifiedStamp">(Q)</sup>: null}</td>
                                                                </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div style={{ flex: "1", margin: "0px 10px" }}>
                                                <table className="scoreboardTable" cellspacing="5" style={{ width: "100%" }}>
                                                    <thead className="tableHeader">
                                                        <th colspan="2" style={{ textAlign: "left", paddingLeft: "60px" }}>ATHLETE</th>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.results.slice(0, this.state.limit_finalists).slice(Math.round(this.state.results.slice(0, this.state.limit_finalists).length / 2), this.state.results.slice(0, this.state.limit_finalists).length).sort((a, b) => a.run_order - b.run_order).map((item, index) => {
                                                                return <tr className="athleteBox" style={{ borderTop: "4px solid rgb(97, 218, 251)" }} onClick={e=>this.goTo(item.athlete_id)}>
                                                                    <td className="rankCell">{item.run_order}</td>
                                                                    <td className="nameCell" style={{ paddingLeft: "20px" }}>{item.first_name} {item.last_name} {item.qualified == 1 ? <sup className="qualifiedStamp">(Q)</sup>: null}</td>
                                                                </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        :

                                        <div style={{ marginTop: "10px" }}>
                                            <table className="scoreboardTable" cellspacing="5" style={{ width: "100%" }}>
                                                <thead className="tableHeader">
                                                    <th colspan="2" style={{ textAlign: "left", paddingLeft: "60px" }}>ATHLETE</th>
                                                </thead>
                                                <tbody>
                                                    {this.state.results.slice(0, this.state.limit_finalists).sort((a, b) => a.run_order - b.run_order).map((item, index) => {
                                                            return <tr className="athleteBox" style={{ borderTop: "4px solid rgb(97, 218, 251)" }} onClick={e=>this.goTo(item.athlete_id)}>
                                                                <td className="rankCell">{item.run_order}</td>
                                                                <td className="nameCell" style={{ paddingLeft: "20px" }}>{item.first_name} {item.last_name} {item.qualified == 1 ? <sup className="qualifiedStamp">(Q)</sup>: null}</td>
                                                            </tr>

                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    :

                                    this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).length > 10 ?
                                        <div style={{ marginTop: "10px", display: "flex" }}>
                                            <div style={{ flex: "1", margin: "0px 10px" }}>
                                                <table className="scoreboardTable" cellspacing="5" style={{ width: "100%" }}>
                                                    <thead className="tableHeader">
                                                        <th colspan="2" style={{ textAlign: "left", paddingLeft: "60px" }}>ATHLETE</th>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).slice(0, Math.round(this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).length / 2)).sort((a, b) => a.run_order - b.run_order).map((item, index) => {
                                                            if (item.gender == this.state.scoreboard_gender) {
                                                                return <tr className="athleteBox" onClick={e=>this.goTo(item.athlete_id)}>
                                                                    <td className="rankCell">{item.run_order}</td>
                                                                    <td className="nameCell" style={{ paddingLeft: "20px" }}>{item.first_name} {item.last_name} {item.qualified == 1 ? <sup className="qualifiedStamp">(Q)</sup>: null}</td>
                                                                </tr>
                                                            }
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div style={{ flex: "1", margin: "0px 10px" }}>
                                                <table className="scoreboardTable" cellspacing="5" style={{ width: "100%" }}>
                                                    <thead className="tableHeader">
                                                        <th colspan="2" style={{ textAlign: "left", paddingLeft: "60px" }}>ATHLETE</th>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).slice(Math.round(this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).length / 2), this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).length).sort((a, b) => a.run_order - b.run_order).map((item, index) => {
                                                            if (item.gender == this.state.scoreboard_gender) {
                                                                return <tr className="athleteBox" style={{ borderTop: "4px solid rgb(97, 218, 251)" }} onClick={e=>this.goTo(item.athlete_id)}>
                                                                    <td className="rankCell">{item.run_order}</td>
                                                                    <td className="nameCell" style={{ paddingLeft: "20px" }}>{item.first_name} {item.last_name} {item.qualified == 1 ? <sup className="qualifiedStamp">(Q)</sup>: null}</td>
                                                                </tr>
                                                            }
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                        :

                                        <div style={{ marginTop: "10px" }}>
                                            <table className="scoreboardTable" cellspacing="5" style={{ width: "100%" }}>
                                                <thead className="tableHeader">
                                                    <th colspan="2" style={{ textAlign: "left", paddingLeft: "60px" }}>ATHLETE</th>
                                                </thead>
                                                <tbody>
                                                    {this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).sort((a, b) => a.run_order - b.run_order).map((item, index) => {
                                                        if (item.gender == this.state.scoreboard_gender) {
                                                            return <tr className="athleteBox" style={{ borderTop: "4px solid rgb(97, 218, 251)" }} onClick={e=>this.goTo(item.athlete_id)}>
                                                                <td className="rankCell">{item.run_order}</td>
                                                                <td className="nameCell" style={{ paddingLeft: "20px" }}>{item.first_name} {item.last_name} {item.qualified == 1 ? <sup className="qualifiedStamp">(Q)</sup>: null}</td>
                                                            </tr>
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                    :

                                    this.state.course_type != 'overall_results' ?
                                        this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).length > 10 ?
                                            <div style={{ marginTop: "10px", display: "flex" }}>
                                                <div style={{ flex: "1", margin: "0px 10px" }}>
                                                    <table className="scoreboardTable" cellspacing="5" style={{ width: "100%" }}>
                                                        <thead className="tableHeader">
                                                            <th colspan="2" style={{ textAlign: "left", paddingLeft: "60px" }}>ATHLETE</th>
                                                            <th>LOC</th>
                                                            <th>TF</th>
                                                            <th>TIME</th>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).slice(0, Math.ceil(this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).length / 2), this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).length).sort((a, b) => a.athlete_rank - b.athlete_rank).map((item, index) => {
                                                                if (item.gender == this.state.scoreboard_gender) {
                                                                    return <tr className="athleteBox" onClick={e=>this.goTo(item.athlete_id)}>
                                                                        <td className="rankCell">{item.athlete_rank}</td>
                                                                        <td className="nameCell" style={{ paddingLeft: "20px" }}>{item.first_name} {item.last_name} {item.qualified == 1 ? <sup className="qualifiedStamp">(Q)</sup>: null}</td>
                                                                        <td className="locCell">{item.points}</td>
                                                                        <td className="locCell">{item.total_fails}</td>
                                                                        {this.state.course_type != 'overall_results' && item.tiebreak_time != '' && item.tiebreak_time != null ? <td className="tieTime timeCell">{item.tiebreak_time.substr(3)}</td> : null}
                                                                    </tr>
                                                                }
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    <div className="legend">*LOC = Last Obstacle Completed | TF = Total Fails</div>
                                                </div>
                                                <div style={{ flex: "1", margin: "0px 10px" }}>
                                                    <table className="scoreboardTable" cellspacing="5" style={{ width: "100%" }}>
                                                        <thead className="tableHeader">
                                                            <th colspan="2" style={{ textAlign: "left", paddingLeft: "60px" }}>ATHLETE</th>
                                                            <th>LOC</th>
                                                            <th>TF</th>
                                                            <th>TIME</th>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).slice(Math.ceil(this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).length / 2), this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).length).sort((a, b) => a.athlete_rank - b.athlete_rank).map((item, index) => {
                                                                if (item.gender == this.state.scoreboard_gender) {
                                                                    return <tr className="athleteBox" onClick={e=>this.goTo(item.athlete_id)}>
                                                                        <td className="rankCell">{item.athlete_rank}</td>
                                                                        <td className="nameCell" style={{ paddingLeft: "20px" }}>{item.first_name} {item.last_name} {item.qualified == 1 ? <sup className="qualifiedStamp">(Q)</sup>: null}</td>
                                                                        <td className="locCell">{item.points}</td>
                                                                        <td className="locCell">{item.total_fails}</td>
                                                                        {this.state.course_type != 'overall_results' && item.tiebreak_time != '' && item.tiebreak_time != null ? <td className="tieTime timeCell">{item.tiebreak_time.substr(3)}</td> : null}
                                                                    </tr>
                                                                }
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    <div className="legend">*LOC = Last Obstacle Completed | TF = Total Fails</div>
                                                </div>

                                            </div>
                                            :
                                            <div style={{ marginTop: "10px" }}>
                                                <table className="scoreboardTable" cellspacing="5" style={{ width: "100%" }}>
                                                    <thead className="tableHeader">
                                                        <th colspan="2" style={{ textAlign: "left", paddingLeft: "60px" }}>ATHLETE</th>
                                                        <th>LOC</th>
                                                        <th>TF</th>
                                                        <th>TIME</th>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).sort((a, b) => a.athlete_rank - b.athlete_rank).map((item, index) => {
                                                            if (item.gender == this.state.scoreboard_gender) {
                                                                return <tr className="athleteBox" onClick={e=>this.goTo(item.athlete_id)}>
                                                                    <td className="rankCell">{item.athlete_rank}</td>
                                                                    <td className="nameCell" style={{ paddingLeft: "20px" }}>{item.first_name} {item.last_name} {item.qualified == 1 ? <sup className="qualifiedStamp">(Q)</sup>: null}</td>
                                                                    <td className="locCell">{item.points}</td>
                                                                    <td className="locCell">{item.total_fails}</td>
                                                                    {this.state.course_type != 'overall_results' && item.tiebreak_time != '' && item.tiebreak_time != null ? <td className="tieTime timeCell">{item.tiebreak_time.substr(3)}</td> : <td className="tieTime timeCell"></td>}
                                                                </tr>
                                                            }
                                                        })}
                                                    </tbody>
                                                </table>
                                                <div className="legend">*LOC = Last Obstacle Completed | TF = Total Fails</div>
                                            </div>
                                        :
                                        // Place conditional here to choose between advanced details or not
                                        <div style={{ marginTop: "10px" }}>
                                            <table className="scoreboardTable overallTable" style={{ width: "100%" }}>
                                                <thead className="tableHeader">
                                                    <th colspan="2" style={{ textAlign: "left", paddingLeft: "60px" }}>ATHLETE</th>
                                                    <th>Points</th>
                                                    <th>Speed</th>
                                                    <th>Hybrid</th>
                                                    <th>Burnout</th>
                                                    {this.state.advanced_details ? <th>TB1</th> : null}
                                                    {this.state.advanced_details ? <th>TB2</th> : null}
                                                    {this.state.advanced_details ? <th>Open</th> : null}
                                                </thead>
                                                <tbody>
                                                    {this.state.results.filter(gen=>gen.gender==this.state.scoreboard_gender).slice(0, this.state.limit_finalists).sort((a, b) => a.athlete_rank - b.athlete_rank).map((item, index) => {
                                                        if (item.gender == this.state.scoreboard_gender) {
                                                            return <tr className="athleteBox" onClick={e=>this.goTo(item.athlete_id)}>
                                                                <td className="rankCell">{item.athlete_rank}</td>
                                                                <td className="nameCell" style={{ paddingLeft: "20px" }}>{item.first_name} {item.last_name} {item.qualified == 1 ? <sup className="qualifiedStamp">(Q)</sup>: null}</td>
                                                                <td className="locCell">{item.points}</td>
                                                                <td className="locCell">{item.speed_rank}</td>
                                                                <td className="locCell">{item.hybrid_rank}</td>
                                                                <td className="locCell">{item.burnout_rank}</td>
                                                                {this.state.advanced_details ? <td className="locCell">{item.top_two_score}</td> : null}
                                                                {this.state.advanced_details ? <td className="locCell">{item.top_score}</td> : null}
                                                                {this.state.advanced_details ? <td className="locCell">{item.open_rank}</td> : null}
                                                            </tr>
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                            {this.state.advanced_details ? <div className="legend">* TB1 = Tie Breaker 1 (Top 2 Places) | TB2 = Tie Breaker 2 (Top Place)</div> : null}
                                        </div>

                                }

                            </div>

                        </div>

                    </div>
                    : this.state.comp_id != '' ? <div className="legend" style={{margin: "20px"}}>Data is not yet available for this competiton</div>: null}
            </div>
        );
    }
}


export default PublicLeaderboard;